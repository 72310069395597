import React from "react"
import { Link,useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import path from 'path'

import '../styles/work.scss'

class WorkStuff extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            items: props.items
        }
    }
    checkPassword(e) {
        const passwordInput = document.querySelector('#password')
        if (passwordInput.value == 'xMj5Ru2dafa') {
            this.setState({
                open: true
            })
        } else {
            passwordInput.invalid = true
        }
    }
    render() {
        if (this.state.open) {
            return (
                <>
                    {
                        this.state.items.map((work, index) => {
                            return (
                                <article className='item' key={index}>
                                    <Link to={`${work.frontmatter.slug}`}>
                                        <h3>{work.frontmatter.title}</h3>
                                        <p>{work.frontmatter.description}</p>
                                    </Link>
                                </article>
                            )
                        })
                    }
                </>
            )
            
        } else {
            return (
                <>
                    <p>Please enter the supplied password to view my portfolio.</p>
                    <input type='password' id='password'/><button style={{
                        margin: '0 0 0 1em'
                    }} onClick={this.checkPassword.bind(this)}>View</button>
                    <span style={{
                        float: 'right'
                    }}><small><a href='mailto:nathan.akrill@gmail.com'>Contact me</a> for access.</small></span>
                </>
            )
        }
    }
}

const IndexPage = () => {
    const {allMarkdownRemark: {nodes: allWork}} = useStaticQuery(graphql`
        query {
            allMarkdownRemark(
                limit: 1000,
                sort: {order: DESC, fields: frontmatter___date}
            ) {
                nodes {
                    frontmatter {
                      slug
                      title
                      description
                    }
                }
            }
        }
    `);
    return(
  <Layout>
    <SEO title="Home" />
    <main className='work'>
      <article>
          <h2>Work</h2>
          <WorkStuff items={allWork} />
      </article>
    </main>
    <span><small>&copy; Nathan Akrill</small></span>
  </Layout>
)}

export default IndexPage
